import React from 'react'
import SinglePageLayout from '../components/SinglePageLayout';
import SEO from '../components/seo';

function TermsOfService() {
    return (
        <SinglePageLayout>
            <SEO title="Terms Of Service" />
            <div className="container-page">
                <div className="page-hero hero">
                    <h1 className="page-title">Terms Of Service</h1>
                    <p className="page-desc">On this page you can learn more about our terms of service. These terms apply for both Toggol's website and Toggol's desktop application.</p>
                </div>
                <div className="page-content">
                <h2 className="text-center my-5">Desktop "Toggol" App License</h2>
                <div>
                    <p>
                        Copyright {`(`}c{`)`} 2020 Igor Piatkov, all rights reserved.<br />
                        <br />
                        Permission is hereby granted to any person obtaining a copy of this software and associated documentation files {`(`}the "Software"{`)`}, to deal in the Software under restrictions and limitations. Any person obtaining a copy of this software granted a "use" right only. Copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software are NOT ALLOWED.
                    </p>
                    <p>
                        The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
                    </p>
                    <p>
                        THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
                    </p>
                </div>
                <h2 className="text-center">Toggol.com Terms Of Service</h2>
                <h2>1. Terms</h2>
                <p>By accessing this Website and desktop Application, accessible from https://toggol.com, you are agreeing to be bound by these Website and desktop Application Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website and desktop Application are protected by copyright and trade mark law.</p>

                <h2>2. Use License</h2>

                <p>Permission is granted to temporarily download one copy of the materials on Toggol's Website and desktop Application for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

                <ul className="list">
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose or for any public display;</li>
                    <li>attempt to reverse engineer any software contained on Toggol's Website and desktop Application;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                </ul>

                <p>This will let Toggol to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

                <h2>3. Disclaimer</h2>

                <p>All the materials on Toggol’s Website and desktop Application are provided "as is". Toggol makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Toggol does not make any representations concerning the accuracy or reliability of the use of the materials on its Website and desktop Application or otherwise relating to such materials or any sites linked to this Website and desktop Application.</p>

                <h2>4. Limitations</h2>

                <p>Toggol or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Toggol’s Website and desktop Application, even if Toggol or an authorize representative of this Website and desktop Application has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

                <h2>5. Revisions and Errata</h2>

                <p>The materials appearing on Toggol’s Website and desktop Application may include technical, typographical, or photographic errors. Toggol will not promise that any of the materials in this Website and desktop Application are accurate, complete, or current. Toggol may change the materials contained on its Website and desktop Application at any time without notice. Toggol does not make any commitment to update the materials.</p>

                <h2>6. Links</h2>

                <p>Toggol has not reviewed all of the sites linked to its Website and desktop Application and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Toggol of the site. The use of any linked website and desktop application is at the user’s own risk.</p>

                <h2>7. Site Terms of Use Modifications</h2>

                <p>Toggol may revise these Terms of Use for its Website and desktop Application at any time without prior notice. By using this Website and desktop Application, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

                <h2>8. Your Privacy</h2>

                <p>Please read <a href="https://www.privacypolicygenerator.info/">our Privacy Policy</a>.</p>

                <h2>9. Governing Law</h2>

                <p>Any claim related to Toggol's Website and desktop Application shall be governed by the laws of us without regards to its conflict of law provisions.</p>
                </div>
            </div>
        </SinglePageLayout>
    )
}

export default TermsOfService
